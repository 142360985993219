import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IMGUPLOAD from "../../../../src/institute-dashboard-assets/images/Content_upload.svg"
import IMGVALIDATION from "../../../../src/institute-dashboard-assets/images/Content_Validation.svg"
import IMGTESTS from "../../../../src/institute-dashboard-assets/images/Test_Creation.svg"
import IMGWORKSHEET from "../../../../src/institute-dashboard-assets/images/Worksheet_Creation.svg"
import IMGKARYAKATA from "../../../../src/institute-dashboard-assets/images/Karyakarta_Analysis.svg"
import { GET_CONTENT_DATA, GET_CONTENT_DATA_GRAPH } from "../../../app/service/content-module/dashboard-service";
import { toast } from "react-toastify";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ContentProgressBar from "../../../components/charts-graphs/progress-bar";
import { GET_KARYAKARTA_LIST } from "../../../app/service/content-module/karyakarta-service";
import ContentPieChart from "../../../components/charts-graphs/content-pie-chart";

export const ContentDashboard = () => {
    const [contentInfo, setContentInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [karyakartaList, setKarayakartaList] = useState<any>([]);
    const history = useHistory();
    const [categoriesUpload, setCategoriesUpload] = useState([]);
    const [categoriesTest, setCategoriesTest] = useState([]);
    const userRole = localStorage.getItem("user_role");
    const [seriesUpload, setSeriesUpload] = useState<any>([
        { name: "Upload", type: "line", color: "#145F83", data: [] },
        { name: "Validate", type: "line", color: "#DC773C", data: [] },
        { name: "Series 3", type: "line", color: "#1F6D2F", data: [] },
    ]);
    const [seriesTest, setSeriesTest] = useState<any>([
        { name: "Test", type: "line", color: "#145F83", data: [] },
        { name: "Worksheet", type: "line", color: "#DC773C", data: [] },
        { name: "Series 3", type: "line", color: "#1F6D2F", data: [] },
    ]);
    const [userAccess, setUserAcess] = useState<any>({
        uplaod: false,
        validation: false,
        tests: false,
        worksheets: false,
        karyakarta: false
    })
    useEffect(() => {
        getUserAccess();
        fetchAllData();
    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await getContentData();
            await getGraphData();
            await getKaryakartaData();
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    };


    const getContentData = async () => {
        try {
            const payLoad = {
                dashboard_stats: true
            }
            const res: any = await GET_CONTENT_DATA(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    setContentInfo(res);
                }
            }
        } catch (error) {

        }
    }
    const getGraphData = async () => {
        try {
            const payLoad = {
                weekly: true
            }
            const res: any = await GET_CONTENT_DATA_GRAPH(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res?.result
                    const preUpload = data?.weekly_question_uploads?.map((item: any) => item?.count);
                    const preValidation = data?.weekly_question_validated?.map((item: any) => item?.count);
                    setSeriesUpload((prevSeries: any) => [
                        { ...prevSeries[0], data: preUpload },
                        { ...prevSeries[1], data: preValidation },
                    ]);
                    const preweekly = data?.weekly_question_uploads?.map((item: any) =>
                        item?.week
                    );
                    setCategoriesUpload(preweekly);
                    const preTest = data?.weekly_tests_created?.map((item: any) => item?.count);
                    const preWorksheet = data?.weekly_worksheets_created?.map((item: any) => item?.count);
                    setSeriesTest((prevSeries: any) => [
                        { ...prevSeries[0], data: preTest },
                        { ...prevSeries[1], data: preWorksheet },
                    ]);
                    const preweeklyTest = data?.weekly_tests_created?.map((item: any) =>
                        item?.week
                    );
                    setCategoriesTest(preweeklyTest);
                }
            }
        } catch (error) {

        }
    }
    const getKaryakartaData = async () => {
        try {
            const payLoad = {
                user_role: "TEACHER,PARENT,DATA_ENTRY_OPERATOR,SUBJECT_COORDINATOR,CONTENT_VALIDATOR,CONTENT_HEAD,SUBJECT_TEAM_LEAD,SUBJECT_HEAD,IAS_ADMIN,MANAGEMENT,SUPER_ADMIN"
            }
            const res: any = await GET_KARYAKARTA_LIST(payLoad);
            if (res) {
                if (res.status === 'fail') {
                } else {
                    if (res?.rows?.length > 0) {
                        setKarayakartaList(res?.rows);
                    }
                }
            }
        } catch (error) {

        }
    }
    const getUserAccess = () => {
        const data = userAccess;
        if (userRole === "IAS_ADMIN" || userRole === "SUPER_ADMIN") {
            data.uplaod = true;
            data.validation = true;
            data.tests = true;
            data.worksheets = true;
            data.karyakarta = true;
            setUserAcess({ ...data });
        } else if (userRole === "SUBJECT_HEAD" || userRole === "CONTENT_HEAD") {
            data.uplaod = true;
            data.validation = true;
            data.tests = true;
            data.worksheets = true;
            setUserAcess({ ...data });
        } else if (userRole === "SUBJECT_COORDINATOR") {
            data.uplaod = true;
            data.tests = true;
            data.worksheets = true;
            setUserAcess({ ...data });
        } else if (userRole === "DATA_ENTRY_OPERATOR") {
            data.uplaod = true;
            setUserAcess({ ...data });
        } else if (userRole === "DATA_ENTRY_OPERATOR") {
            data.validation = true;
            setUserAcess({ ...data });
        } else {
            setUserAcess({ ...data });

        }
    }

    const upLoads = () => {
        history.push(`/dashboard/content/upload/boards-list`);
    }
    const valiDations = () => {
        history.push(`/dashboard/content/question-validation`);
    }
    const testCreation = () => {
        history.push(`/dashboard/content/pariksha/test-creation`);
    }
    const worksheetCreation = () => {
        history.push(`/dashboard/content/abhyaas/worksheet`);
    }
    const karyaKarthaAnalysis = () => {
        history.push(`/dashboard/content/karyakarta/list`);
    }


    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="border-top border-primary py-3">
                    <h4 className="mt-2  border-bottom pb-2">Content Dashboard</h4>
                    <div className="content_cards_list mt-3">
                        {userAccess?.uplaod && <div className=" ps-3">
                            <div>
                                <div className="content_card cursor_pointer  " onClick={upLoads}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGUPLOAD} />
                                    </div>
                                    <h5 className="f16 mb-0">Content<br /> Upload</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold up_value cn_wide-text">{contentInfo?.content_upload ? contentInfo?.content_upload : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Content Upload</h5>
                            </div>
                        </div>}
                        {(userAccess?.uplaod && userAccess?.validation) && <div className="cn_vl"></div>}
                        {userAccess?.validation && <div>
                            <div className="content_card cursor_pointer cn_validation_bg" onClick={valiDations}>
                                <div className="pe-3 me-3 border_right_1 ">
                                    <img className="content_img mt-1 " src={IMGVALIDATION} />
                                </div>
                                <h5 className="f16 mb-0">Content<br />  Validation</h5>
                            </div>
                            <div style={{ height: "100px", width: "170px" }}>
                                <ContentPieChart contentInfo={contentInfo} />
                            </div>
                            <h5 className="f16 mb-0 text-center mt-5 pt-2">Content Validation</h5>
                        </div>}
                        {(userAccess?.tests) && <div className="cn_vl"></div>}
                        {userAccess?.tests && <div>
                            <div>
                                <div className="content_card cursor_pointer cn_test_bg" onClick={testCreation}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGTESTS} />
                                    </div>
                                    <h5 className="f16 mb-0">Test <br /> Creation</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold test_value cn_wide-text">{contentInfo?.tests_created ? contentInfo?.tests_created : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Test Created</h5>
                            </div>
                        </div>}
                        {userAccess?.worksheets && <div className="cn_vl"></div>}
                        {userAccess?.worksheets && <div>
                            <div>
                                <div className="content_card cursor_pointer cn_worksheet_bg" onClick={worksheetCreation}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGWORKSHEET} />
                                    </div>
                                    <h5 className="f16 mb-0">Worksheet<br />  Creation</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold worksheet_value cn_wide-text">{contentInfo?.worksheets_created ? contentInfo?.worksheets_created : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Worksheet Created</h5>
                            </div>
                        </div>}
                        {userAccess?.karyakarta && <div className="cn_vl"></div>}
                        {userAccess?.karyakarta && <div>
                            <div>
                                <div className="content_card cursor_pointer cn_karakartas_bg" onClick={karyaKarthaAnalysis}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGKARYAKATA} />
                                    </div>
                                    <h5 className="f16 mb-0">Karyakartas<br />  Analysis</h5>
                                </div>
                            </div>
                            <div className="mt-5 pt-5">
                                <h2 className="fw-bold cn_karya_txt cn_wide-text text-center">{karyakartaList?.length}</h2>
                                <h5 className="f16 mb-0 text-center ">Total Karyakartas</h5>
                            </div>
                        </div>}
                    </div>
                    <div className="border-top pt-2 mt-5 ">
                        <div className="content_chart_list mt-5">
                            <div>
                                <ContentProgressBar title={"Upload & Validation Chart"} categories={categoriesUpload} series={seriesUpload} />
                            </div>
                            <div>
                                <ContentProgressBar title={"Test & Worksheet Chart"} categories={categoriesTest} series={seriesTest} />
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}